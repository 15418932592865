import {includes, isEqual, map} from "lodash";
import {Dispatch} from "redux";
import {safelyParsePage} from "@web2/string_utils";

import {IOfferListOfferResponse} from "../../../app/interfaces/response/offer_list";
import {IOfferListApiResponse, IOfferListApiResponseMeta, IOfferListResponseSSR} from "../../../app/interfaces/response/server_list_response";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString, IQueryParams} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {IOfferListFetchParams} from "../../utils/get_offer_list_fetch_params";
import {getDefaultOfferCountPerPage} from "./fetch_offer_list_at_route";

export const fetchOfferListTypes = createRequestActionTypes({name: "fetch", type: "GET", view: "offer_list"});

export const fetchOfferListWithUniqueParams =
    (services: Partial<IServices>, params: IOfferListFetchParams, onSuccess?: (offersResponse: IOfferListOfferResponse[]) => void) =>
    (dispatch: Dispatch, getState: () => IStore): Promise<void | null | IOfferListResponseSSR<IOfferListOfferResponse>> => {
        // guard against refetching with the same query
        const {latestQuery} = getState().offerList;
        // ignore `mapView` param that is used to determine if map view is active, we don't want to trigger fetches because it changed
        const {mapView, ...validParams} = params;
        if (isEqual(validParams, latestQuery)) {
            return Promise.resolve(null);
        }
        return dispatch(fetchOfferList(services, params, onSuccess));
    };

const fallbackMeta: IOfferListApiResponseMeta = {
    total_investments: 0,
    total_offers: 0,
    total_offers_not_collapsed: 0,
    total_results: 0,
    total_individual_offers: 0,
    features: [],
    to_extend: false,
    distance: 0
};

export const fetchOfferList =
    (services: Partial<IServices>, params: IOfferListFetchParams, onSuccess?: (offersResponse: IOfferListOfferResponse[]) => void) =>
    (dispatch: Dispatch, getState: () => IStore) => {
        dispatch({type: fetchOfferListTypes.start, latestQuery: params});

        const url = appendQueryString(apiLink.offers.base({})(null), parsedOfferParams(params));

        return getRequest(services, url, "fetchOfferList")
            .then((response: IOfferListApiResponse<IOfferListOfferResponse>) => {
                if (response && response.data) {
                    const collectionCount = (() => {
                        if (response.meta && response.meta.total_results) {
                            return response.meta.total_results;
                        }
                        return 0;
                    })();

                    const validatedOffers = filterOffersWithoutSlug(response.data || []);

                    const result: IOfferListResponseSSR<IOfferListOfferResponse> = {
                        collectionCount,
                        meta: response.meta || fallbackMeta,
                        data: validatedOffers,
                        pageCount: Math.ceil(collectionCount / getDefaultOfferCountPerPage({offerType: params.offer_type})),
                        page: (params.page && safelyParsePage(params.page)) || 1
                    };
                    dispatch({type: fetchOfferListTypes.success, result});
                    onSuccess && onSuccess(result.data);
                    return result;
                }
            })
            .catch(catchAbortedError(() => null))
            .catch((err) => {
                console.error(err);
                return null;
            });
    };

export const parsedOfferParams = (params: IQueryParams) => {
    // So, BE is using two type of rooms param - ?rooms=4,5 and ?rooms__gte=4.
    // Our architecture is based on constant names of specific filters.
    // Instead of introducing two types of room param name Ive decided to overwrite it here for it still represents our initial approach of rooms=4,5 and
    // is consistent with web1 urls (there were no ?rooms__gte) whenever one chosen 5 rooms.
    if (params.rooms && typeof params.rooms === "string" && includes(params.rooms, "5")) {
        return {
            ...params,
            rooms: undefined,
            rooms__gte: Math.min(...map(params.rooms.split(","), (x) => +x))
        };
    }

    return params;
};

function filterOffersWithoutSlug(offers: IOfferListOfferResponse[]): IOfferListOfferResponse[] {
    return offers.filter((offer) => {
        if (!offer.slug) {
            console.error(`Offer ${offer.id} has no slug!`);

            return false;
        }

        return true;
    });
}
