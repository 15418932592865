import {Dispatch} from "redux";
import {safelyParsePage} from "@web2/string_utils";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {enable301ResponseState} from "../../../app/utils/request_response_utils/response_state/response_state_actions";
import {TypeSearchFilterOptions} from "../../../search/utils/TypeSearchFilterOptions";
import {OfferDealType, OfferType, OfferTypeEN, UDealTypesPL, UOfferTypes} from "../../utils/constants_offer";
import {getOfferListFetchParams} from "../../utils/get_offer_list_fetch_params";
import {fetchOfferListWithUniqueParams} from "./fetch_offer_list";

export interface ISearchResultRouteParams {
    type?: UDealTypesPL;
    offerType: UOfferTypes;
    location?: string;
    subLocation?: string;
    subSubLocation?: string;
    tag?: string;
}

const DEFAULT_OFFERS_PER_PAGE = 36;
const DEFAULT_OFFERS_PER_PAGE_WITHOUT_ROOMS = 35;

export const getDefaultOfferCountPerPage = (params: {offerType: string | undefined}) => {
    const {offerType} = params;

    return offerType === OfferTypeEN.lot || offerType === OfferType.lot ? DEFAULT_OFFERS_PER_PAGE : DEFAULT_OFFERS_PER_PAGE_WITHOUT_ROOMS;
};

export const MAX_MAP_OFFER_MARKER_COUNT = 500;

export const defaultOffersParams = {
    offer_type: "primary_market,aftermarket,lot",
    page: "1",
    is_active: true
};

export const fetchOfferListAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const location = getState().offerList.location.location;

        const params = getOfferListFetchParams(route, location, getState().search.formValues.offer_type);
        const parsedPage = params.page ? safelyParsePage(params.page) : 1;

        const result = await dispatch(fetchOfferListWithUniqueParams(services, params));
        if (result && result.pageCount < parsedPage && parsedPage > 1) {
            const {page, ...queryWithoutPage} = route.query;
            const firstPageUrl = appendQueryString(route.pathname, queryWithoutPage);
            dispatch(enable301ResponseState(firstPageUrl));
            return null;
        }
        return result;
    };

/**
 * Helpers
 */

const FOR_SALE = "na-sprzedaz";
const FOR_RENT = "do-wynajecia";

export const dealTypeFromUrl = (urlPath: string | undefined): OfferDealType | undefined => {
    switch (urlPath) {
        case FOR_RENT:
            return OfferDealType.RENT;

        case FOR_SALE:
            return OfferDealType.SELL;

        default:
            return undefined;
    }
};

export const offerTypeFromUrl = (urlPath: OfferType): string => {
    switch (urlPath) {
        case OfferType.apartment:
            return offerByType("apartment");

        case OfferType.lot:
            return "lot";

        case OfferType.house:
            return offerByType("house");

        default:
            return "primary_market,aftermarket";
    }
};

export const offerTypeFromQuery = (urlPath: UOfferTypes): TypeSearchFilterOptions[] => {
    switch (urlPath) {
        case OfferType.apartment:
            return [TypeSearchFilterOptions.PRIMARY_APARTMENT, TypeSearchFilterOptions.AFTERMARKET_APARTMENT];

        case OfferType.lot:
            return [TypeSearchFilterOptions.LOT];

        case OfferType.house:
            return [TypeSearchFilterOptions.PRIMARY_HOUSE, TypeSearchFilterOptions.AFTERMARKET_HOUSE];

        default:
            return [
                TypeSearchFilterOptions.PRIMARY_HOUSE,
                TypeSearchFilterOptions.PRIMARY_APARTMENT,
                TypeSearchFilterOptions.AFTERMARKET_HOUSE,
                TypeSearchFilterOptions.AFTERMARKET_APARTMENT
            ];
    }
};

// removes params with falsy values, aka. `empty params` like `&price__lte=`
export function urlParamsValidator(params: Partial<any>, additionalKeys: string[] = []): Partial<any> {
    const keys = ["price__gte", "price__lte", "size__gte", "size__lte", ...additionalKeys];

    return keys.reduce((acc, curr) => {
        const value = params[curr];
        if (value) {
            return {
                ...acc,
                [`${curr}`]: value
            };
        }
        return acc;
    }, {});
}

/**
 * To be moved and possibly erased from the face of the earth.
 */

const offerByType = (type: string) => {
    const arrayOfItems: string[] = [];

    OFFERS().forEach(function filter(item) {
        if (item.value.indexOf(type) !== -1) {
            arrayOfItems.push(item.value);
        }
        if (item.children) {
            item.children.forEach(filter);
        }
    });

    return arrayOfItems.join(",");
};

const OFFERS = (hidePrimary = false): IOfferType[] =>
    (
        [
            !hidePrimary && {
                children: [{label: "house"}, {label: "apartment"}],
                color: "#00a9ff",
                label: "primary_market"
            },
            {
                children: [{label: "house"}, {label: "apartment"}],
                color: "#ff4a38",
                label: "aftermarket"
            },
            {
                color: "#F3D66D",
                label: "lot"
            }
        ] as IOfferConfig[]
    )
        .filter(Boolean)
        .map((item: IOfferConfig): IOfferType => addParentAndValueKey(item));

function addParentAndValueKey(item: IOfferConfig, parent?: IOfferType): IOfferType {
    const {children, color, label} = item;
    const offerType: IOfferType = {
        color,
        label,
        parent,
        value: parent ? `${parent.value}__${item.label}` : item.label
    };

    if (children) {
        offerType.children = children.map((childrenItem: IOfferConfig) => addParentAndValueKey(childrenItem, offerType));
    }

    return offerType;
}

interface IOfferType extends IOfferConfig {
    children?: IOfferType[];
    value: string;
    parent?: IOfferType;
}

export interface IOfferConfig {
    children?: IOfferConfig[];
    color?: string;
    label: UOfferValue;
}

type UOfferValue = "aftermarket" | "direct" | "house" | "apartment" | "agency" | "primary_market" | "lot";

const parseOfferTypeFromUrlEN = (offerType: UOfferTypes | undefined) => {
    switch (offerType) {
        case OfferType.apartment:
            return "apartment";
        case OfferType.house:
            return "house";
        case OfferType.lot:
            return "lot";
        case OfferType.property:
            return "property";
        default:
            return "property";
    }
};
