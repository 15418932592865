import {appPath} from "@web2/gh_routes";

import {apiLink} from "../../../../app/routes/api_link";

export enum VendorType {
    AGENT = "agent",
    AGENCY = "agency",
    DEVELOPER = "developer",
    PRIVATE = "private"
}

export const getVendorBreadcrumb = (type: VendorType): {label: string; link: string} | null => {
    switch (type) {
        case VendorType.AGENCY:
            return {label: "Agencja", link: appPath.agency.base};

        case VendorType.DEVELOPER:
            return {label: "Deweloperzy", link: appPath.developer.base};

        default:
            return null;
    }
};

// kto? co?
export function agencyDeveloper(type: VendorType): string {
    switch (type) {
        case VendorType.AGENCY:
            return "agencja";

        case VendorType.AGENT:
            return "agent";

        case VendorType.DEVELOPER:
            return "deweloper";

        default:
            return "";
    }
}

// kogo? czego?
export function agencyDeveloperGenitive(type: VendorType): string {
    switch (type) {
        case VendorType.AGENCY:
            return "agencję";

        case VendorType.AGENT:
            return "agenta";

        case VendorType.DEVELOPER:
            return "dewelopera";

        default:
            return "";
    }
}

// z kim? z czym?
export function agencyDeveloperAblative(type: VendorType): string {
    switch (type) {
        case VendorType.AGENCY:
            return "agencją";

        case VendorType.AGENT:
            return "agentem";

        case VendorType.DEVELOPER:
            return "deweloperem";

        default:
            return "";
    }
}

// o kim? o co?
export function agencyDeveloperAccusative(type: VendorType): string {
    switch (type) {
        case VendorType.AGENCY:
            return "agencji";

        case VendorType.AGENT:
            return "agenta";

        case VendorType.DEVELOPER:
            return "dewelopera";

        default:
            return "";
    }
}
