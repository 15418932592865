import {find} from "lodash";
import {ISearchResultUniversalParams} from "@web2/gh_routes";

import {IQueryParams} from "../../../app/utils/append_query_string";
import {LocationTypes} from "../../../app/utils/get_location_from_path";
import {RANGE_SETTINGS} from "../../../search/components/filters/obsolete_range_filter/config";
import {parseFormDealTypeToSlug} from "../../../search/utils/parse_offer_type_to_form_values";
import {DealTypeSlug, OfferMarketType, OfferType, UOfferTypes} from "../../utils/constants_offer";
import {IOfferListFetchParams} from "../../utils/get_offer_list_fetch_params";
import {ISearchPageUrlTag, searchPageUrlTags, TAG_PARAMS_TO_WATCH, UrlTagSlug} from "./tags";

export type IOfferListUrlParams<TTagType = UrlTagSlug> = {
    offerType: UOfferTypes | OfferType;
    features?: string; // expect features params to be a string in shape: `[balconies,terraces,last_floor,etc]`
    tag?: TTagType;
} & IQueryParams &
    ISearchResultUniversalParams;

export const getUrlTagDataBySlug = (slug?: string): ISearchPageUrlTag | undefined => {
    return (
        (slug &&
            slug.length > 0 &&
            find(searchPageUrlTags, (tagData) => {
                return tagData && tagData.tag === slug;
            })) ||
        undefined
    );
};

export const getParamsByTag = (offerType: string, slug?: string): IOfferListFetchParams | undefined => {
    const foundTag = getUrlTagDataBySlug(slug);
    return (foundTag && foundTag.params) || (foundTag?.featureName ? {features: `[${foundTag?.featureName}]`} : {});
};

/**
 * returns valid offer list params
 * validates tags and features against offer type
 * validates deal type against offer type
 * deduplicates features under `feature` param, taking `tag` path param into account
 *
 * WARNING: This function is used to determine offer url validity and cause 301 redirects when incorrect URL is spotted.
 * Errors here may result in mess that is hard to fix.
 *
 * @param params: offer list params
 * @param locationType
 */

export const validateOfferListTagWithParams = (params: IOfferListUrlParams<string>, locationType?: LocationTypes): IOfferListUrlParams => {
    // validate deal type
    const validDealType = getValidDealType(params.offerType, parseFormDealTypeToSlug(params.type));
    const {tag: tagFromParams, features: featuresFromParams, market: marketSearchParam, ...restParams} = params;
    restParams.type = validDealType;
    // validate market type search param:
    const isMarketTypeParamValid = marketSearchParam === OfferMarketType.PRIMARY_MARKET;
    if (isMarketTypeParamValid) {
        restParams.market = marketSearchParam;
    }

    /**
     * validate tags
     */

    let tagCount = 0;

    const currentTagData = getUrlTagDataBySlug(tagFromParams);
    const validCurrentTagData = currentTagData && getIsValidTag(currentTagData, restParams, locationType) ? currentTagData : null;
    const featuresListFromParam = getFeaturesListFromParam(featuresFromParams) || [];
    const validParamsTagsData = getValidTagsDataFromParams(restParams, locationType);

    // normalize all received params (features, tags) to a one valid features list with unique elements
    const validFeaturesList = getValidatedFeatures(
        params.offerType,
        ...(validCurrentTagData?.featureName ? [validCurrentTagData.featureName] : []),
        ...featuresListFromParam
    );

    // create a set of unique usable tags tied to "features" param
    const deduplicatedFeaturesList = Array.from(new Set(validFeaturesList));

    /*
     * don't assign tag if more than one tag is possible
     * return only params + features under params list
     */
    tagCount = deduplicatedFeaturesList.length;
    // check if current tag is duplicated in standard params like `rooms`, `price` etc.
    const isCurrentTagDuplicatedInParams = validCurrentTagData?.tag && validParamsTagsData?.some((tagData) => tagData.tag === validCurrentTagData?.tag);
    if (validCurrentTagData?.params && !isCurrentTagDuplicatedInParams) {
        // if currently assigned tag has params, increase feature count
        tagCount++;
    }

    if (validParamsTagsData && validParamsTagsData?.length > 0) {
        // if we can assign more params, increase feature count
        tagCount = tagCount + validParamsTagsData?.length;
    }

    const isCurrentTagValid = currentTagData?.tag === validCurrentTagData?.tag;

    if (tagCount > 1 || !isCurrentTagValid) {
        const featuresParam = getFeaturesParamFromList(deduplicatedFeaturesList);

        return {
            ...restParams,
            ...(currentTagData?.params ? currentTagData?.params : {}),
            ...(featuresParam ? {features: featuresParam} : {})
        };
    }

    /*
     * assign tag on some conditions
     */
    const finalTagData: ISearchPageUrlTag | null =
        getTagDataByFeature(deduplicatedFeaturesList[0]) || validCurrentTagData || (validParamsTagsData && validParamsTagsData[0]);
    const finalTagSlug: UrlTagSlug | null = finalTagData?.tag || null;
    const finalParams: IOfferListUrlParams | null = restParams;

    // add tag to params and remove standard params assigned to this tag
    if (finalParams && finalTagData) {
        finalParams.tag = finalTagData.tag;

        for (const key in finalTagData?.params) {
            if (typeof finalParams?.[key] !== "undefined") {
                delete finalParams[key];
            }
        }
    }

    return {
        ...finalParams,
        ...(finalTagSlug ? {tag: finalTagSlug} : {})
    };
};

/*
 * helpers
 */
type OfferDealTypeValidationData = {
    valid: (DealTypeSlug | undefined)[];
    default: DealTypeSlug | undefined;
};
export const validDealTypesMap: Partial<Record<OfferType, OfferDealTypeValidationData>> = {
    [OfferType.apartment]: {
        valid: [DealTypeSlug.SELL, DealTypeSlug.RENT],
        default: DealTypeSlug.SELL
    },
    [OfferType.house]: {
        valid: [DealTypeSlug.SELL, DealTypeSlug.RENT],
        default: DealTypeSlug.SELL
    },
    [OfferType.lot]: {
        // lots should not use deal type in URL, force undefined
        valid: [undefined],
        default: undefined
    },
    [OfferType.property]: {
        // properties should not use deal type in URL, force undefined
        valid: [undefined],
        default: undefined
    }
};

const getValidDealType = (offerType: OfferType | UOfferTypes, dealType?: DealTypeSlug): DealTypeSlug | undefined => {
    if (!validDealTypesMap[offerType]) {
        // check is not needed
        return dealType;
    }

    const isDealTypeValid = validDealTypesMap[offerType]?.valid.some((dt) => dt === dealType);
    return dealType && isDealTypeValid ? dealType : validDealTypesMap[offerType]?.default;
};

// returns tag data based on param value
const getTagDataByParam = (paramName: string, params: IQueryParams) =>
    find(searchPageUrlTags, (tagData) => tagData.params?.[paramName]?.toString() === params?.[paramName]?.toString());

// returns tag data based on english feature name
export const getTagDataByFeature = (featureName?: string): ISearchPageUrlTag | null =>
    (featureName && featureName.length > 0 && find(searchPageUrlTags, (tagData) => tagData.featureName === featureName)) || null;

// returns only features assignable to this offer type
const getValidatedFeatures = (offerType: string | undefined, ...featuresList: (string | UrlTagSlug | undefined)[]) => {
    return featuresList.filter((tag) => {
        if (!tag) return false;

        return find(searchPageUrlTags, (tagData) => {
            const isOfferTypeMatched = typeof tagData.offerType === "undefined" || tagData.offerType.some((oType) => oType === offerType);
            return tagData.featureName === tag && isOfferTypeMatched;
        });
    }) as string[];
};

// transforms search param "features=[balconies,building,sea]" into a usable array
export const getFeaturesListFromParam = (features: string | undefined) => features?.slice(1, features?.length - 1).split(",");
// transforms a usable array into search param "features=[balconies,building,sea]"
const getFeaturesParamFromList = (features: string[]) => (features.length > 0 ? `[${features.join(",")}]` : null);

/**
 * parses offer list params and returns array containing all valid tags data
 * @param params - offer list params
 * @param locationType
 */
const getValidTagsDataFromParams = (params: IOfferListUrlParams<string>, locationType: LocationTypes | undefined): ISearchPageUrlTag[] | null => {
    const tagsFromParams: ISearchPageUrlTag[] = [];
    for (const param of TAG_PARAMS_TO_WATCH) {
        const paramsTagData = params[param] && getTagDataByParam(param, params);
        if (paramsTagData && getIsValidTag(paramsTagData, params, locationType)) {
            tagsFromParams.push(paramsTagData);
        }
    }
    return tagsFromParams.length > 0 ? tagsFromParams : null;
};

// returns if tag is valid based on types
const getIsValidTag = (tagData: ISearchPageUrlTag, params: {offerType?: string; type?: string}, locationType: LocationTypes | undefined) => {
    // filter by offer type ,deal type and location only if it is defined in `searchPageUrlTags`
    const ignoreUndefinedOfferType = typeof tagData.offerType === "undefined";
    const isOfferTypeValid = ignoreUndefinedOfferType || tagData.offerType?.some((oType) => oType === params.offerType);

    const ignoreUndefinedDealType = typeof tagData.dealType === "undefined";
    const isDealTypeValid = ignoreUndefinedDealType || tagData.dealType === params.type;

    const ignoreUndefinedLocationType = typeof tagData.locationTypes === "undefined";
    const isLocationTypeValid = ignoreUndefinedLocationType || tagData.locationTypes?.some((type) => type === locationType);

    return isOfferTypeValid && isDealTypeValid && isLocationTypeValid;
};

export const getTagMetaText = (tag: UrlTagSlug, metaText: string | undefined) => {
    switch (tag) {
        case UrlTagSlug.BUILDING:
            return "budowlana";
        case UrlTagSlug.RECREATION:
            return "rekreacyjna";
        case UrlTagSlug.UTILITIES:
            return "uzbrojona";
        default:
            return metaText;
    }
};

export const validateRangeFilterParams = (params: IOfferListUrlParams) => {
    const validatedParams = {...params};

    for (const key in validatedParams) {
        const testedParam = validatedParams[key];
        const parsedValue = testedParam ? Number(testedParam) : undefined;
        const rangeKey = key.toUpperCase();

        if (key.includes("__lte") && parsedValue && parsedValue > RANGE_SETTINGS[rangeKey]) {
            validatedParams[key] = RANGE_SETTINGS[rangeKey].toString();
        }

        if (parsedValue === 0 || (key.includes("__gte") && parsedValue && parsedValue < RANGE_SETTINGS[rangeKey])) {
            validatedParams[key] = RANGE_SETTINGS[rangeKey].toString();
        }
    }

    return validatedParams;
};
