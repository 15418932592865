import {map} from "lodash";

import {IBreadcrumb} from "../../app/utils/breadcrumbs";
import {dealTypeToUrl} from "../../app/utils/deal_type_to_url";
import {getVendorBreadcrumb, VendorType} from "../detail/components/agency_and_developer/agency_and_developer_utils";
import {ILocationShort} from "../list/actions/fetch_location_by_slug_at_route";
import {getOfferListUrl} from "../list/url_utils/get_offer_list_url";
import {getUrlTagDataBySlug, validDealTypesMap} from "../list/url_utils/tag_utils";
import {IOfferListQueryType, IOfferType, OfferDealType, OfferTypeEN, translateQueryOfferTypesIntoOfferType} from "./constants_offer";
import {dealTypePL, translateOfferType} from "./utils";

// TODO: these breadcrumbs are generated in similar fashion as on offer detail pages. They can probably be unified
export const generateOfferListBreadcrumbs = (
    locationPath: ILocationShort[] | undefined,
    dealType: OfferDealType,
    queryTypeString: string,
    tagSlug: string | undefined
) => {
    const queryTypes: IOfferListQueryType[] = queryTypeString.split(",") as IOfferListQueryType[];
    const offerType = translateQueryOfferTypesIntoOfferType(queryTypes);

    const listingBreadcrumbs: IBreadcrumb[] = getListingTypesBreadcrumbs(offerType, dealType);

    const basePathName = getLastBreadcrumbPathName(listingBreadcrumbs);
    const locationBreadCrumbs = getLocationBreadcrumbs(locationPath || [], basePathName);

    const tagData = getUrlTagDataBySlug(tagSlug);

    const tagBreadcrumb: IBreadcrumb[] = tagData?.breadcrumbText
        ? [
              {
                  label: tagData.breadcrumbText,
                  link: getOfferListUrl({
                      offerType: getOfferTypeSlugPL(offerType),
                      type: dealTypeToUrl(dealType),
                      tag: tagData.tag
                  })
              }
          ]
        : [];

    return [...listingBreadcrumbs, ...locationBreadCrumbs, ...tagBreadcrumb];
};

export const getListingTypesBreadcrumbs = (offerType: IOfferType, dealType: OfferDealType): IBreadcrumb[] => {
    const hasDefaultDealType = !!validDealTypesMap[translateOfferType(offerType)]?.default;
    return hasDefaultDealType ? getJoinedTypeBreadCrumb(offerType, dealType) : getSeparateTypesBreadCrumbs(offerType, dealType);
};

/**
 * returns two breadcrumbs, ie. "nieruchomości" -> "na sprzedaż"
 * @param offerType
 * @param dealType
 */
const getSeparateTypesBreadCrumbs = (offerType: IOfferType, dealType: OfferDealType): IBreadcrumb[] => {
    const offerTypeBreadcrumb: IBreadcrumb = {
        label: getOfferTypePL(offerType),
        link: getOfferListUrl({
            offerType: getOfferTypeSlugPL(offerType),
            type: dealTypeToUrl(dealType)
        })
    };
    const listingBreadcrumbs: IBreadcrumb[] = [offerTypeBreadcrumb];

    if (dealType && offerType !== "lot") {
        const dealTypeBreadcrumb: IBreadcrumb = {
            label: `${dealTypePL(dealType)}`,
            link: getOfferListUrl({
                offerType: getOfferTypeSlugPL(offerType),
                type: dealTypeToUrl(dealType)
            })
        };
        listingBreadcrumbs.push(dealTypeBreadcrumb);
    }

    return listingBreadcrumbs;
};

/**
 * returns one breadcrumb, ie. "mieszkania na sprzedaż"
 * @param offerType
 * @param dealType
 */
const getJoinedTypeBreadCrumb = (offerType: IOfferType, dealType: OfferDealType): IBreadcrumb[] => {
    return [
        {
            label: `${getOfferTypePL(offerType)} ${dealTypePL(dealType)}`,
            link: getOfferListUrl({
                offerType: getOfferTypeSlugPL(offerType),
                type: dealTypeToUrl(dealType)
            })
        }
    ];
};

const getLastBreadcrumbPathName = (list: IBreadcrumb[]): string => list[list.length - 1].link;

const getLocationBreadcrumbs = (path: ILocationShort[], basePathName: string): IBreadcrumb[] =>
    map(path, (p) => ({
        label: p.name,
        link: `${basePathName}${p.slug}/`
    }));

function getOfferTypePL(type: IOfferType): string {
    switch (type) {
        case OfferTypeEN.apartment:
            return "Mieszkania";
        case OfferTypeEN.lot:
            return "Działki";
        case OfferTypeEN.house:
            return "Domy";
        case OfferTypeEN.property:
            return "Nieruchomości";
        default:
            return "Nieruchomości";
    }
}

function getOfferTypeSlugPL(type: IOfferType) {
    switch (type) {
        case OfferTypeEN.apartment:
            return "mieszkania";
        case OfferTypeEN.lot:
            return "dzialki";
        case OfferTypeEN.house:
            return "domy";
        case OfferTypeEN.property:
            return "nieruchomosci";
        default:
            return "nieruchomosci";
    }
}

export const generateVendorBreadcrumbs = (vendorType: VendorType, breadcrumbs: IBreadcrumb | IBreadcrumb[]): IBreadcrumb[] => {
    const breadcrumbsArray: IBreadcrumb[] = [];
    const firstBreadcrumb = getVendorBreadcrumb(vendorType);

    if (firstBreadcrumb !== null) {
        breadcrumbsArray.push(firstBreadcrumb);
    }

    if (Array.isArray(breadcrumbs)) {
        breadcrumbsArray.push(...breadcrumbs);
    } else {
        breadcrumbsArray.push(breadcrumbs);
    }

    return breadcrumbsArray;
};
