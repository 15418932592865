import {IQueryParam, IQueryParams} from "../../app/utils/append_query_string";
import {FormValuesType} from "../../search/actions/search_form_actions";
import {ILocation} from "../list/actions/fetch_location_by_slug_at_route";
import {
    dealTypeFromUrl,
    defaultOffersParams,
    getDefaultOfferCountPerPage,
    ISearchResultRouteParams,
    urlParamsValidator
} from "../list/actions/fetch_offer_list_at_route";
import {getParamsByTag} from "../list/url_utils/tag_utils";
import {OfferDealType, OfferType, OfferTypeEN, UOfferTypes} from "./constants_offer";

interface IRouteData {
    state?: {searchFormValues?: FormValuesType};
    query: Record<string, string | string[]>;
    params: ISearchResultRouteParams;
}

export interface IOfferListFetchParams extends IQueryParams {
    deal_type?: OfferDealType;
    is_active?: boolean;
    location?: string;
    offer_type?: string;
    page?: string | string[];
    per_page?: number;
    rooms?: string | string[];
    features?: string;
}

/**
 * generates params for offer list fetching
 * @param routeData
 * @param location
 * @param search_form_offer_type - this has to come in the same weird format it is stored in search form, ie. 'aftermarket__apartment,primary_market__apartment,aftermarket__house,primary_market__house'
 */
export const getOfferListFetchParams = (routeData: IRouteData, location: ILocation | null, search_form_offer_type: string): IOfferListFetchParams => {
    const deal_type = dealTypeFromUrl(routeData.params.type);
    const query = {
        ...routeData.query,
        ...(routeData.query.rooms ? {rooms: routeData.query.rooms} : {})
    };

    const paramsFromTag = getParamsByTag(routeData.params.offerType, routeData.params.tag);

    // market type may be selected or known on some conditions
    const marketTypeParam = routeData.query.market || paramsFromTag?.market;

    // offer_type is a special case parameter - it is not always reflected in url, so we have to pull it from somewhere or assume.
    // checking offer_type from history entry (current routeData.state) here in case user used browser 'back'/'forward'
    const userChosenOfferType: string[] | undefined = routeData.state?.searchFormValues?.offer_type || search_form_offer_type;
    const userChosenOfferTypeString = userChosenOfferType && userChosenOfferType.join(",");

    const offerType =
        getOfferTypeFromParams(routeData.params.offerType, marketTypeParam) ||
        userChosenOfferTypeString ||
        parseUrlOfferType(routeData.params.offerType, deal_type);

    const hasRooms = !!(query.rooms || paramsFromTag?.rooms);

    return {
        ...defaultOffersParams,
        per_page: getDefaultOfferCountPerPage({offerType: routeData.params.offerType}),
        offer_type: offerType,
        ...query,
        ...(deal_type ? {deal_type} : {}),
        ...urlParamsValidator(routeData.query, ["name", "geo_point"]),
        ...paramsFromTag,
        ...(location && location.id ? {location: location.id} : {})
    };
};

const getOfferTypeFromParams = (offerType: UOfferTypes, marketTypeParam: IQueryParam) => {
    if (marketTypeParam && (offerType === OfferType.house || offerType === OfferType.apartment || offerType === OfferType.property)) {
        switch (offerType) {
            case OfferType.house:
                return `${marketTypeParam}__${OfferTypeEN.house}`;
            case OfferType.apartment:
                return `${marketTypeParam}__${OfferTypeEN.apartment}`;
            default:
                return `${marketTypeParam}__${OfferTypeEN.apartment},${marketTypeParam}__${OfferTypeEN.house}`;
        }
    }
    return;
};

const parseUrlOfferType = (type: UOfferTypes, deal_type: OfferDealType | undefined): string => {
    switch (type) {
        case OfferType.house:
            if (deal_type === OfferDealType.RENT) {
                return "aftermarket__house";
            }
            return "aftermarket__house,primary_market__house";
        case OfferType.lot:
            return "lot";
        case OfferType.apartment:
            if (deal_type === OfferDealType.RENT) {
                return "aftermarket__apartment";
            }
            return "aftermarket__apartment,primary_market__apartment";
        case OfferType.property:
            if (deal_type === OfferDealType.RENT) {
                return "aftermarket__apartment,aftermarket__house";
            }
            return "aftermarket__apartment,primary_market__apartment,aftermarket__house,primary_market__house";
        default:
            return "aftermarket__apartment,primary_market__apartment,aftermarket__house,primary_market__house";
    }
};
